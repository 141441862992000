import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";
import { DataGrid } from "@mui/x-data-grid";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import { convertDateTimeFormat } from "../utils";
import { deleteWants } from "../../redux/actions/wants";
import ConfirmationModal from "../ConfirmationModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";

const AlliWantTable = ({
  viewWantHandler,
  data,
  loading,
  deleteAlliwantHandler,
}) => {
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [deleteModalStatus, setDeleteModalStatus] = useState({
    status: false,
    id: null,
  });

  useEffect(() => {
    const closeDropdown = (event) => {
      if (
        event.target.id !== "Dropdown" &&
        event.target.nodeName !== "circle"
      ) {
        setSelectedDropdown(null);
      }
    };

    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  });

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            <img
              className="w-8 h-8 rounded-full mr-2 bg-cover object-cover"
              src={
                params?.row["images"] && params?.row["images"].length > 0
                  ? params?.row["images"][0].url
                  : "/images/Avatar.png"
              }
            />
            <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate capitalize">
              {params.row && params?.row?.data?.generalInfo["title"]}
            </div>
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "price",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] capitalize">
            {params.row && params.row.data.generalInfo.price}
          </div>
        );
      },
    },
    {
      field: "created At",
      headerName: "created At",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] capitalize">
            {params.row && convertDateTimeFormat(params.row.created_at)}
          </div>
        );
      },
    },
    {
      field: "ThreeDots",
      headerName: "",
      width: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <div
              className=" h-6 w-8 cursor-pointer"
              id={`Dropdown`}
              onClick={() => {
                console.log("clicking", params);
                if (selectedDropdown !== null) {
                  // document.getElementById(`payout-${selectedDropdown}`).style.display = "none";
                }
                if (selectedDropdown === params.id) {
                  setSelectedDropdown(null);
                  // document.getElementById(`payout-${params.id}`).style.display = "none";
                  return;
                }
                setSelectedDropdown(params.id);
              }}
            >
              <MoreVertIcon id={`Dropdown`}></MoreVertIcon>
              {/* <svg
                                id={`Dropdown`}
                                width="3"
                                height="4"
                                viewBox="0 0 3 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="1.5"
                                    cy="2"
                                    r="1.5"
                                    fill="#D9D9D9"
                                />
                            </svg>
                            <svg
                                id={`Dropdown`}
                                width="3"
                                height="4"
                                viewBox="0 0 3 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="1.5"
                                    cy="2"
                                    r="1.5"
                                    fill="#D9D9D9"
                                />
                            </svg>
                            <svg
                                id={`Dropdown`}
                                width="3"
                                height="4"
                                viewBox="0 0 3 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="1.5"
                                    cy="2"
                                    r="1.5"
                                    fill="#D9D9D9"
                                />
                            </svg> */}
            </div>
            <ul
              id={`payout-${params.row.id}`}
              className={`${
                selectedDropdown !== null && selectedDropdown === params.id
                  ? ` block`
                  : `hidden`
              } select-none origin-top-right absolute right-[20px] mt-[100px] w-32  rounded-[6px] shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none `}
              style={{
                boxShadow: "0px 0px 10px -3px rgba(0, 0, 0, 0.1)",
              }}
            >
              <li>
                <button
                  id={"tags"}
                  className="hover:bg-slate-100 group flex rounded-md items-center  w-full px-4 py-2 "
                  onClick={() => {
                    viewWantHandler(params.row);
                    // props.updateWantHandler(params.row);
                  }}
                >
                  <svg
                    className="w-4 h-4 mr-2 text-gray-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    ></path>
                  </svg>
                  <p className="hover:text-slate-900 text-slate-600 text-sm text-Inter">
                    View
                  </p>
                </button>
              </li>
              <li>
                <button
                  id={"tags"}
                  onClick={() => {
                    setDeleteModalStatus({
                      status: true,
                      id: params.row.id,
                    });
                  }}
                  className="hover:bg-red-100 group flex rounded-md items-center  w-full px-4 py-2 "
                >
                  <svg
                    className="w-4 h-4 mr-2 text-gray-600 group-hover:text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    ></path>
                  </svg>
                  <p className="group-hover:text-red-500 text-slate-600 text-sm text-Inter">
                    Delete
                  </p>
                </button>
              </li>
            </ul>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="w-full px-8 fade-in">
        <div className="w-full px-5 pt-3 pb-1.5 border-[1px] border-[#EAECF0] rounded-lg">
          <div className="flex item-center justify-between">
            <div className="">
              <p className="text-lg text-secondary font-semibold text-Inter">
                Wants Table
              </p>
              <p className="text-sm text-secondarySupport text-Inter">
                All the items in your inventory will appear here.
              </p>
            </div>
          </div>

          <div style={{ height: 600, width: "97%" }} className="mt-5">
            <DataGrid
              sx={{
                overflowX: "scroll",
                border: 0,
                "& .css-17jjc08-MuiDataGrid-footerContainer": {
                  borderTop: 0,
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "18px",
                  fontFamily: "Inter",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "1.15rem",
                },
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-selectedRowCount": {
                  visibility: "hidden",
                },
              }}
              rows={data}
              columns={columns}
              loading={loading}
              getRowId={(row) => Math.random() * 1000000}
              disableRowSelectionOnClick={true}
              // onRowClick={(row) => {
              //   viewWantHandler(row.row);
              // }}
            />
          </div>
        </div>
      </div>

      {deleteModalStatus.status && (
        <ConfirmationModal
          heading={"Delete this want?"}
          message={"You can't undo this action."}
          onCancel={() =>
            setDeleteModalStatus({
              status: false,
              id: null,
            })
          }
          onConfirm={() => {
            deleteAlliwantHandler(deleteModalStatus.id);
            setDeleteModalStatus({
              status: false,
              id: null,
            });
          }}
        />
      )}
    </div>
  );
};

export default AlliWantTable;
